// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: #4d4c4c;
  --ion-color-primary-rgb: 112, 68, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #494848;
  --ion-color-primary-tint: #969494;
  /** secondary **/
  --ion-color-secondary: #0079ab;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #00425e;
  --ion-color-secondary-tint: #00acf6;
  /** tertiary **/
  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 56, 128, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #191919;
  --ion-color-tertiary-tint: #4d4c4c;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f03f4c;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #ffb5b5;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-info: #5bc0de;

  --ion-color-inactive: #e98516;

  --ion-color-notification: #FBE364;

  --highlight: #00aeef;
  --font-title-size: 14px;
  --font-regular-size: 11px;
  --font-handsontable-size: 12px;
  --font-small-size: 8px;
  --icon-size: 30px;
  --border-width: 3px;
  --toast-border-radius: 15px;

  --menu-width: 275px;
  --min-height: 40px;

  --padding-start: 10px;
  --padding-end: 10px;
}

@import "../assets/scss/glyphicons/glyphicons";

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../assets/fonts/glyphicons/glyphicons-halflings-regular.eot');
  src: url('../assets/fonts/glyphicons/glyphicons-halflings-regular.eot') format('embedded-opentype'), url('../assets/fonts/glyphicons/glyphicons-halflings-regular.woff2') format('woff2'), url('../assets/fonts/glyphicons/glyphicons-halflings-regular.woff') format('woff'), url('../assets/fonts/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'), url('../assets/fonts/glyphicons/glyphicons-halflings-regular.svg') format('svg');
}

* {
  font-size: var(--font-regular-size);
}

* ::-webkit-scrollbar {
  display: none;
}

body {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

a:hover {
  color: var(--ion-color-primary-tint);
}

.numeric {
  text-align: right;
}

$toolbar-ios-title-font-size: var(--font-title-size);
$toolbar-md-title-font-size: var(--font-title-size);
$text-input-highlight-color-valid: transparent;
$text-input-highlight-color-invalid: transparent;

ion-menu {
  --background: var(--ion-color-primary);
  --width: var(--menu-width);
}

div.menu {
  text-align: center;
}

ion-header.menu {
  background-color: var(--ion-color-primary-contrast) !important;
  color: var(--ion-color-dark);
}

ion-list.menu {
  background-color: var(--ion-color-primary) !important;
}

ion-content.menu,
ion-item.menu {
  --background: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary-contrast);
  cursor: pointer;
}

ion-item.textarea {
  --min-height: 75px;
  justify-content: flex-start;
  align-items: baseline;
  vertical-align: top;
  text-align: left;
}

ion-input,
div.select {
  background-color: var(--ion-color-primary-contrast);
}

// div.input-wrapper {
//   height: 30px !important;
// }

ion-item {
  --min-height: 30px !important;
  min-height: 30px !important;
  // height: 37px !important;
}

ion-item.select {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px;
}

ion-item.filter {
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --inner-border-width: 0px;
  --background: transparent;
}

ion-grid {
  --ion-grid-padding: 0px;
}

ion-button.circle {
  --border-radius: 50%;
  --padding-start: 8px;
  --padding-end: 8px;
  margin: 0;
}

ion-segment {
  padding: 5px;
}

fa-icon.page:hover {
  color: var(--ion-color-secondary);
}

.dark {
  color: var(--ion-color-dark);
}

.connection {
  width: 100%;
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
  font-weight: bolder;
  padding-top: 5px;
  padding-bottom: 5px;
}

.success {
  --background: var(--ion-color-success);
  --border-radius: var(--toast-border-radius);
  font-weight: bolder;
}

.warning {
  --background: var(--ion-color-warning);
  --color: var(--ion-color-dark);
  --border-radius: var(--toast-border-radius);
  font-weight: bolder;
}

.error {
  --background: var(--ion-color-danger);
  --border-radius: var(--toast-border-radius);
  font-weight: bolder;
}

.info {
  --background: var(--ion-color-info);
  --color: var(--ion-color-dark);
  --border-radius: var(--toast-border-radius);
  font-weight: bolder;
}

.ion-item-button {
  width: 100%;
}

.errorcontainer {
  padding-top: 5px;
  padding-left: 35px;
  font-size: 12px;
}

.listerrorcontainer {
  padding-top: 5px;
  font-size: 12px;
}

.errormessage {
  color: var(--ion-color-danger);
}

.suggestion {
  color: var(--ion-color-secondary);
}

.hidden {
  display: none;
  visibility: none;
}

.label {
  font-size: var(--font-regular-size) !important;
  font-weight: bold;
}

i {
  color: var(--ion-color-primary-tint);
  font-size: var(--font-small-size);
}

div.searchbar {
  background-color: var(--ion-color-primary);
}

ion-row.searchbar {
  background-color: var(--ion-color-primary);
}

div.main {
  max-height: 750px;
  overflow-y: scroll;
}

div.sublist {
  max-height: 350px;
  overflow-y: scroll;
}

ion-item.searchbar {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-dark);
  --placeholder-color: var (--ion-color-medium);
  --border-color: var(--ion-color-medium);
  --border-width: 1px;
  --border-radius: 35px;
}

ion-item.form {
  --border-color: var(--ion-color-medium);
  --border-width: 1px;
  --border-radius: 10px;
}

ion-item.required {
  border-left: 4px solid var(--ion-color-danger);
  border-right: 1px solid var(--ion-color-medium);
  border-radius: 13px;
}

ion-item.blank {
  --background: transparent !important;
}

.content {
  background-color: var(--ion-color-light);
  padding: 15px;
  border-radius: 15px;
}

ion-toolbar {
  --background: var(--ion-color-primary);
}

ion-menu-button {
  --color: var(--ion-color-primary-contrast);
}

.datagrid {
  cursor: pointer;
}

ion-row.row.released>ion-col {
  background-color: var(--released-color);
  padding: 10px 5px -15px;
  border: 2px solid #FFFFFF;
}

ion-row.row.odd>ion-col {
  background-color: rgba(244, 244, 244, 0.5);
  padding: 10px 5px -15px;
  border: 2px solid #FFFFFF;
}

ion-row.row.even>ion-col {
  background-color: rgba(152, 154, 162, 0.35);
  padding: 10px 5px -15px;
  border: 2px solid #FFFFFF;
}

.row:hover {
  background-color: var(--highlight);
}

.gridheader {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-weight: bolder;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.col-left {
  text-align: left;
}

.col-center {
  text-align: center;
}

.col-right {
  text-align: right;
}

.roundbutton {
  padding: 15px;
  font-size: 125%;
  border-radius: 50%;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  cursor: pointer;
  margin-left: 10px;
}

.roundbutton:hover {
  background-color: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
}

.roundbutton-outline {
  padding: 15px;
  font-size: 125%;
  border-radius: 50%;
  border: 3px solid var(--ion-color-primary);
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  cursor: pointer;
  margin-left: 10px;
}

.roundbutton-outline:hover {
  color: var(--ion-color-primary-shade);
  border-color: var(--ion-color-primary-shade);
  background-color: var(--ion-color-light);
}

.outlinebutton {
  background-color: transparent;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.outlinebutton:hover {
  color: var(--ion-color-primary-shade);
}

select.ionicselect {
  border: 0px;
  outline: 0px;
  width: 100%;
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;

  select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 0 15px;
    outline: 0;
    border: 0;
    border-radius: 0;
    background: var(--ion-color-primary-contrast);
    color: var(--ion-color-dark);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  select:hover,
  select:focus {
    color: var(--ion-color-primary-dark);
    background: var(--ion-color-primary-contrast);
  }

  select:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  select:hover~.selectarrow,
  select:focus~.selectarrow {
    border-top-color: var(--ion-color-primary-dark);
  }

  select:disabled~.selectarrow {
    border-top-color: var(--ion-color-light);
  }

  .selectarrow {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: var(--ion-color-medium) transparent transparent transparent;
  }
}

div.page {
  width: 120px;
  border: 1px solid var(--ion-color-medium);
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;

  .selectarrow {
    top: 15px;
  }
}

.popoverwrapper {
  position: relative;
  z-index: 1000;
}

div.popover {
  display: inline;
  cursor: pointer;
}

.popovercontent {
  opacity: 0;
  min-width: 250px;
  visibility: hidden;
  position: absolute;
  margin-top: 30px;
  left: -110px;
  transform: translate(0, 10px);
  background-color: var(--ion-color-light);
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
  border-radius: 25px;
  color: var(--ion-color-dark);
}

.popovercontent:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--ion-color-light) transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popoverwrappershow .popovercontent {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popovermessage {
  text-align: center;
}

.popovertext {
  text-align: left;
  word-wrap: normal;
}

label.class {
  left: 25px;
}

div.exportfilter {
  display: inline;
}

div.filtertitle {
  display: inline;
  cursor: pointer;
}

div.filterlabel {
  display: inline;
}

div.filter {
  max-height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}

div.dropdown {
  display: inline;
  cursor: pointer;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}

.inactive {
  background-color: var(--ion-color-inactive);
}

.filter {
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
}

ion-toolbar {
  height: 46px !important;
}

div.toolbar-container {
  min-height: 40px !important;
  max-height: 40px !important;
  height: 40px !important;
}

.sc-ion-buttons-md-s .button {
  height: 16px !important;
}

div.titlecontainer {
  position: relative;
  height: 35px;

  div.title {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
  }
}

span.title {
  font-size: var(--font-title-size);
  font-weight: 800;
  color: var(--ion-color-primary-contrast);
}

div.view {
  width: 100%;
}

span.view {
  color: var(--ion-color-dark-tint);
  white-space: pre-line;
}

select.placeholder {
  color: var(--ion-color-medium);
}

ion-icon.fab {
  font-size: 30px;
}

.switch-field {
  display: flex;
  overflow: hidden;

  div {
    margin-top: 15px;
    margin-bottom: 15px;

    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
    }

    label {
      background-color: var(--ion-color-primary-tint);
      color: var(--ionic-color-medium);
      line-height: 1;
      text-align: center;
      padding: 12px 18px;
      margin-right: -1px;
      border: 1px solid var(--ionic-color-light);
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
      transition: all 0.1s ease-in-out;
    }

    label:hover {
      cursor: pointer;
      color: var(--ion-color-primary-contrast);
    }

    input:checked+label {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      box-shadow: none;
    }

    input:checked:disabled+label {
      background-color: var(--ion-color-medium);
    }

    input:disabled+label {
      background-color: var(--ion-color-light);
    }

    input:checked:disabled+label:hover {
      cursor: default;
      color: var(--ion-color-primary-contrast);
    }

    input:disabled+label:hover {
      cursor: default;
      color: var(--ion-color-primary-medium);
    }
  }

  div:first-child label {
    border-radius: 10px 0 0 10px;
  }

  div:last-child label {
    border-radius: 0 10px 10px 0;
  }
}

div.accordion {
  padding: 10px;

  h4 {
    cursor: pointer;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    padding: 12px 18px;
    border-radius: 10px 10px 0 0;
    font-size: 14px;
    font-weight: 800;
    margin: 0;
  }

  h4.hide {
    border-radius: 10px;
  }

  div.accordionbody {
    border: 1px solid var(--ion-color-medium);
  }
}

div.accordionalt {
  padding: 0;
}

hr.divider {
  border-top: 1px solid var(--ion-color-medium);
  border-width: 1px;
}

.control input:focus~.control__indicator {
  border-color: var(--ion-color-primary-contrast);
}

.control input:disabled~.control__indicator {
  background: var(--ion-color-dark-tint);
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked~.control__indicator:after {
  display: block;
}

.control--checkbox .control__indicator:after {
  left: 6px;
  top: 3px;
  width: 3px;
  height: 8px;
  border-style: solid;
  border-color: var(--ion-color-primary-contrast);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control--checkbox input:disabled~.control__indicator:after,
.control--radio input:disabled~.control__indicator:after {
  border-color: var(--ion-color-light-shade);
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 5px;
  left: 15px;
  height: 20px;
  width: 20px;
  background: var(--ion-color-light-shade);
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-primary-contrast);
}

.control input[type="radio"]~.control__indicator {
  border-radius: 50%;
}

.control:hover input~.control__indicator {
  background: var(--ion-color-primary-tint);
}

.control input:checked~.control__indicator {
  background: var(--ion-color-primary);
}

.control input[type="radio"]:checked~.control__indicator:after {
  position: absolute;
  top: 6px;
  left: 6px;
  height: 4px;
  width: 4px;
  background: var(--ion-color-primary-contrast);
  border-color: var(--ion-color-primary-contrast);
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}

.control:hover input~.control__indicator,
.pointer,
.clickable {
  cursor: pointer;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.dashboard {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  padding: 25px;
  color: white;
}

h1.dashboardlabel {
  font-size: 28px;
  font-weight: 600;
}

span.dashboard {
  font-size: 60px;
  font-weight: 800;
}

span.dashboard2 {
  font-size: 24px;
  font-weight: 800;
}

span.dashboard3 {
  font-size: 30px;
  font-weight: 800;
}

ion-icon.fabsmall {
  font-size: 20px;
}

.disabled {
  background-color: var(--ion-color-light);
}

.notification {
  background-color: var(--ion-color-notification);
}

ion-segment.boxed {
  --border-style: solid;
  --border-width: 1px;
  --indicator-height: 0px;
  --color-checked: var(--ion-color-primary);
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.handsontable .htCommentCell:after {
  border-top: 6px solid red !important
}

.htCore thead>tr>th {
  background-color: var(--ion-color-primary);
  color: #FFFFFF;
  font-weight: 800;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.ht_master tr:nth-of-type(even)>td {
  background-color: #EFEFEF;
}

.ht_master tr>td.required {
  border-left: 4px solid var(--ion-color-danger);
}

.htCore thead>tr>th.ht__highlight {
  background-color: var(--ion-color-primary-shade);
}

.ht_master tr.highlight>td {
  background-color: var(--highlight);
  font-weight: 600;
}

.ht_master tr.inactive>td {
  background-color: var(--ion-color-inactive);
}

.ht_master tr.error>td {
  background-color: var(--ion-color-danger-tint);
}

.ht_master tr.hoterror>td {
  background-color: var(--ion-color-danger-tint);
}

.htCommentTextArea {
  background-color: var(--ion-color-danger-tint);
}

.handsontable.listbox tr td.current,
.handsontable.listbox tr:hover td {
  background: var(--highlight) !important;
}

div.popupwrapper {
  margin-left: 10px;
  position: absolute;
  overflow-y: auto;
  max-height: 120px;
  width: 85%;
  z-index: 1000;
  border: solid;
  border-width: thin;
  border-color: var(--ion-color-dark-tint);
}

ion-item.autocomplete:hover {
  --background: var(--highlight);
  cursor: pointer;
}

input.datetime {
  border: none;
  width: 100%;
}

input.datetime:focus {
  border: none;
}

div.handsontable.small {
  font-size: var(--font-handsontable-size);
}

div.handsontable.small {
  td {
    white-space: nowrap;
    max-width: 0px !important;
  }
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modalcontent {
  background-color: #ffff99;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 98%;
  /* Could be more or less, depending on screen size */
  border-radius: 25px;
}

/* The Close Button */
.modalclose {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding-right: 10px;
}

.modalclose:hover,
.modalclose:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

@media print {
  .printable {
    display: none;
  }
}

.vvport {
  height: 200px;
  width: 100%;
  background-color: #FFFFFF;
}

ion-item.vvportitem {
  height: 38px;
  --background: transparent;
  cursor: pointer;
}

ion-item.vvportitem:hover {
  --background: var(--highlight);
  font-weight: 800;
}

// @media only screen and (max-device-width: 480px) {

// }

.sc-ion-input-md {
  --padding-top: 5px;
  --padding-bottom: 5px;
}

.transparent {
  background-color: transparent !important;
}

.slot {
  margin-left: 5px;
  margin-right: 5px;
  flex: none;
}

div.item-native {
  padding-left: unset !important;
  padding-right: unset !important;
  padding-inline-start: 10 !important;
}

div.item-inner {
  padding-left: unset !important;
  padding-right: unset !important;
  padding-inline-end: 10 !important;
}

.nospace {
  padding: 0;
  margin: 0;
}